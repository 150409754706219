import { Button } from '@/components/ui/button';
import Link from 'next/link';

export default function Custom404() {
  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-center">
      <h1 className="mb-5">404 - Page Not Found</h1>
      <Button asChild>
        <Link href="/">Return Home</Link>
      </Button>
    </div>
  );
}
